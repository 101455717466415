/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import 'bootstrap/dist/css/bootstrap.min.css';
import './src/components/layout.css'
import './src/components/nav.css'
import './src/components/nav_collapsed.css'
import './src/components/main_slider.css'
import './src/components/our_methods.css'
import './src/components/our_works.css'
import './src/components/our_services.css'
import './src/components/our_prebuilt_systems.css'
import './src/components/open_source_software.css'
import './src/components/our_contact.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "animate.css"
